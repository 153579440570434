import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import styled from "styled-components"

const ProjectEstimationHeader = () => {
  // PARALLAX EFFECT
  const [offsetY, setOffsetY] = useState(0)
  const handleScroll = () => setOffsetY(window.pageYOffset)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <StyledContainer fluid>
      <Header>
        <ImageWrapper>
          <StaticImage
            className="headerBg"
            src="./multimedia/project-estimation.png"
            alt="Project Estimation"
            imgStyle={{ transform: `translateY(${offsetY * 0.8}px)` }} // PARALLAX EFFECT
          />
        </ImageWrapper>
        <Paragraph>Project Estimation</Paragraph>
      </Header>
    </StyledContainer>
  )
}

export default ProjectEstimationHeader

// Styled Component

const StyledContainer = styled(Container)`
  padding-right: 0;
  padding-left: 0;
`

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  position: relative;
  color: white;

  @media screen and (max-width: 1024px) {
    height: 25vh;
  }

  & .headerBg {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    z-index: -1;

    @media screen and (max-width: 1024px) {
      object-fit: contain;
      object-position: center top;
    }
  }
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(46, 49, 49, 0.3);
`

const Paragraph = styled.p`
  position: absolute;
  left: 10%;
  bottom: 5%;
  width: 50%;
  font-family: Maven Pro;
  font-weight: 900;
  font-style: normal;
  font-size: clamp(32px, 5vw, 48px);
  line-height: clamp(40px, 5vw, 56px);
  letter-spacing:0.08em;
  color: #ffb700;
`
