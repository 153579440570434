import React, { useState } from "react"
import Slider from "rc-slider"
import "rc-slider/assets/index.css"
import { Container, Form, Row, Col, Button } from "react-bootstrap"
import styled from "styled-components"
import attachment from "./multimedia/attachment.png"
import { RobotoForms, RobotoSmall } from "../styles/PageStyles.js"

const { createSliderWithTooltip } = Slider
const Range = createSliderWithTooltip(Slider.Range)

const ProjectEstimationForm = () => {
  const [projects, setProject] = useState([])
  const [file, setFile] = useState({ attachment: "", link: "" })
  const [fileKey, setFileKey] = useState(0)
  const [projectBudget, setProjectBudget] = useState([500000, 1600000])

  const addProject = e => {
    if (e.target.checked === true) {
      // if checkbox is checked, then add the project
      setProject([
        ...projects,
        {
          value: e.target.value,
        },
      ])
    } else {
      setProject(projects.filter(project => project.value !== e.target.value)) // if checkbox is not checked, then remove the project
    }
  }

  const allProjects = projects.map(project => " " + project.value)

  const handleChange = e => {
    try {
      setFile({
        ...file,
        [e.target.name]: e.target.files[0].name,
      })
    } catch (error) {
      file.attachment = null
    }
  }

  const fileClear = () => {
    setFile({ attachment: "" })
    setFileKey(fileKey + 1)
  }

  const handleSliderChange = projectBudget => {
    setProjectBudget(projectBudget)
  }

  return (
    <StyledContainer fluid>
      <StyledForm
        name="project-estimation-form-v1.2"
        method="post"
        data-netlify="true"
        onSubmit="submit"
        action="/thank-you-for-your-submission"
        data-netlify-honeypot="bot-field"
      >
        <input
          type="hidden"
          name="form-name"
          value="project-estimation-form-v1.2"
        />

        {/* PERSONAL INFORMATION SECTION */}
        <PersonalInformation>
          <FormTitle>
            <RobotoForms>Your personal information *</RobotoForms>
            <RobotoSmall
              style={{
                display: "flex",
                alignItems: "center",
                color: "#FFB700",
              }}
            >
              THIS IS REQUIRED
            </RobotoSmall>
          </FormTitle>
          <div style={{ width: "100%" }}>
            <Form.Group controlId="formName">
              <Form.Control
                type="text"
                name="name"
                placeholder="Your name"
                required
              />
            </Form.Group>
          </div>
          <Wrapper>
            <FormRow>
              <Form.Group controlId="formEmail">
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Your email"
                  required
                />
              </Form.Group>
            </FormRow>
            <WhiteSpace />
            <FormRow>
              <Form.Group controlId="formContactNumber">
                <Form.Control
                  type="text"
                  name="contact-number"
                  placeholder="Your contact number"
                  required
                />
              </Form.Group>
            </FormRow>
          </Wrapper>
        </PersonalInformation>

        {/* TYPE OF PROJECT SECTION */}
        <div>
          <FormTitle>
            <RobotoForms>What are the types of your project? *</RobotoForms>
            <RobotoSmall
              style={{
                display: "flex",
                alignItems: "center",
                color: "#FFB700",
              }}
            >
              THIS IS REQUIRED
            </RobotoSmall>
          </FormTitle>
          <Row>
            <label htmlFor="projects" hidden>
              Type of Project
            </label>
            <input
              name="type-of-project"
              id="projects"
              value={allProjects}
              hidden
            />
            <StyledCol lg={4} xs={6}>
              <Input
                id="web-development"
                type="checkbox"
                onChange={addProject}
                value="Web Development"
              />
              <Label htmlFor="web-development">Web Development</Label>
            </StyledCol>
            <StyledCol lg={4} xs={6}>
              <Input
                id="mobile-app"
                type="checkbox"
                onChange={addProject}
                value={"Mobile Application"}
              />
              <Label htmlFor="mobile-app">Mobile Application</Label>
            </StyledCol>
            <StyledCol lg={4} xs={6}>
              <Input
                id="brand-identity"
                type="checkbox"
                onChange={addProject}
                value="Brand Identity"
              />
              <Label htmlFor="brand-identity">Brand Identity</Label>
            </StyledCol>
            <StyledCol lg={4} xs={6}>
              <Input
                id="api"
                type="checkbox"
                onChange={addProject}
                value="API & Integration"
              />
              <Label htmlFor="api">API & Integration</Label>
            </StyledCol>
            <StyledCol lg={4} xs={6}>
              <Input
                id="cloudSolutions"
                type="checkbox"
                onChange={addProject}
                value="Cloud Solutions"
              />
              <Label htmlFor="cloudSolutions">Cloud Solutions</Label>
            </StyledCol>
            <StyledCol lg={4} xs={6}>
              <Input
                id="ui-ux"
                type="checkbox"
                onChange={addProject}
                value="UX/UI"
              />
              <Label htmlFor="ui-ux">UX/UI</Label>
            </StyledCol>
          </Row>
        </div>

        {/* DESCRIPTION OF THE PROJECT SECTION */}
        <div>
          <FormTitle>
            <RobotoForms>Can you give us an idea?</RobotoForms>
          </FormTitle>
          <Form.Group controlId="formDescription">
            <Desc
              as="textarea"
              rows={5}
              name="description"
              placeholder="Just a short description of your requirements or problem"
            />
          </Form.Group>
          <Form.Group style={{ display: "flex", alignItems: "center" }}>
            <Form.File
              key={fileKey}
              id="file"
              name="attachment"
              onChange={handleChange}
              hidden
            />
            <label htmlFor="file" style={{ cursor: "pointer", margin: "0" }}>
              <img
                src={attachment}
                alt="Choose a File"
                width="30"
                style={{ marginRight: 15 }}
              />
              {file.attachment === "" ? "Attach a file" : file.attachment}
            </label>
            <Label
              onClick={fileClear}
              style={{
                width: "100px",
                height: "25px",
                margin: "0",
                marginLeft: 25,
              }}
            >
              Clear
            </Label>
          </Form.Group>
        </div>

        {/* BUDGET SECTION */}
        <div>
          <FormTitle>
            <RobotoForms>What is your budget for the project?</RobotoForms>
          </FormTitle>
          <Wrapper>
            <FormRow>
              <StyledSlider>
                <Range
                  name="project-budget"
                  id="budgetSlider"
                  marks={{
                    100000: `PHP 100,000`,
                    200000: ``,
                    300000: ``,
                    400000: ``,
                    500000: ``,
                    600000: ``,
                    700000: ``,
                    800000: ``,
                    900000: ``,
                    1000000: ``,
                    1100000: ``,
                    1200000: ``,
                    1300000: ``,
                    1400000: ``,
                    1500000: ``,
                    1600000: ``,
                    1700000: ``,
                    1800000: ``,
                    1900000: ``,
                    2000000: `PHP 2,000,000`,
                  }}
                  min={100000}
                  max={2000000}
                  // marks={{ 100: 100, 200: 200, 300: 300, 400: 400, 500: 500 }}
                  step={null}
                  defaultValue={projectBudget}
                  onChange={handleSliderChange}
                  allowCross={false}
                  tipFormatter={value => `PHP ${value.toLocaleString()}`}
                  tipProps={{
                    placement: "top",
                    visible: true,
                  }}
                  handleStyle={{
                    borderColor: "#ffb700",
                    height: "20px",
                    width: "20px",
                    backgroundColor: "#fff",
                  }}
                  railStyle={{
                    height: "6px",
                    marginTop: "2px",
                  }}
                  dotStyle={{
                    visibility: "hidden",
                  }}
                />
              </StyledSlider>
              <Form.Group controlId="formMinAmount">
                <Form.Control
                  type="text"
                  placeholder="Minimum Amount"
                  name="minimum-budget"
                  value={`PHP ${projectBudget[0].toLocaleString()}`}
                  hidden
                />
              </Form.Group>
              <Form.Group controlId="formMaxAmount">
                <Form.Control
                  type="text"
                  placeholder="Maximum Amount"
                  name="maximum-budget"
                  value={`PHP ${projectBudget[1].toLocaleString()}`}
                  hidden
                />
              </Form.Group>
            </FormRow>
          </Wrapper>
        </div>

        {/* PROJECT TIMELINE SECTION */}
        <div>
          <FormTitle>
            <RobotoForms>What is your expected project timeline?</RobotoForms>
          </FormTitle>
          <Row style={{ display: "flex", flexDirection: "column" }}>
            <label htmlFor="timeline1" hidden>
              Project Timeline
            </label>
            <StyledCol lg={12} xs={12}>
              <Radio>
                <Input
                  id="timeline1"
                  name="project-timeline"
                  type="radio"
                  value="Less than 3 months"
                />
                <Checkmark htmlFor="timeline1" />
              </Radio>
              <label style={{ cursor: "pointer" }} htmlFor="timeline1">
                Less than 3 months
              </label>
            </StyledCol>
            <StyledCol lg={12} xs={12}>
              <Radio>
                <Input
                  id="timeline2"
                  name="project-timeline"
                  type="radio"
                  value="3-6 months"
                />
                <Checkmark htmlFor="timeline2" />
              </Radio>
              <label style={{ cursor: "pointer" }} htmlFor="timeline2">
                3-6 months
              </label>
            </StyledCol>
            <StyledCol lg={12} xs={12}>
              <Radio>
                <Input
                  id="timeline3"
                  name="project-timeline"
                  type="radio"
                  value="6-12 months"
                />
                <Checkmark htmlFor="timeline3" />
              </Radio>
              <label style={{ cursor: "pointer" }} htmlFor="timeline3">
                6-12 months
              </label>
            </StyledCol>
            <StyledCol lg={12} xs={12}>
              <Radio>
                <Input
                  id="timeline4"
                  name="project-timeline"
                  type="radio"
                  value="Over 12 months"
                />
                <Checkmark htmlFor="timeline4" />
              </Radio>
              <label style={{ cursor: "pointer" }} htmlFor="timeline4">
                Over 12 months
              </label>
            </StyledCol>
          </Row>
        </div>

        {/* YOU GOT HERE BY SECTION */}
        <div>
          <FormTitle>
            <RobotoForms>How did you know about Blackfort?</RobotoForms>
          </FormTitle>
          <Row>
            <label htmlFor="facebook" hidden>
              You got here by
            </label>
            <StyledCol lg={2} xs={6}>
              <Input
                id="facebook"
                name="you-got-here-by"
                type="radio"
                value="Facebook"
              />
              <Label htmlFor="facebook">Facebook</Label>
            </StyledCol>
            <StyledCol lg={2} xs={6}>
              <Input
                id="google"
                name="you-got-here-by"
                type="radio"
                value="Google"
              />
              <Label htmlFor="google">Google</Label>
            </StyledCol>
            <StyledCol lg={2} xs={6}>
              <Input
                id="instagram"
                name="you-got-here-by"
                type="radio"
                value="Instagram"
              />
              <Label htmlFor="instagram">Instagram</Label>
            </StyledCol>
            <StyledCol lg={2} xs={6}>
              <Input
                id="linkedin"
                name="you-got-here-by"
                type="radio"
                value="LinkedIn"
              />
              <Label htmlFor="linkedin">LinkedIn</Label>
            </StyledCol>
            <StyledCol lg={2} xs={6}>
              <Input
                id="friends"
                name="you-got-here-by"
                type="radio"
                value="Friends"
              />
              <Label htmlFor="friends">Friends</Label>
            </StyledCol>
            <StyledCol lg={2} xs={6}>
              <Input
                id="others"
                name="you-got-here-by"
                type="radio"
                value="Others"
              />
              <Label htmlFor="others">Others</Label>
            </StyledCol>
          </Row>
        </div>
        <Submit>
          <StyledButton type="submit">SUBMIT</StyledButton>
        </Submit>
      </StyledForm>
    </StyledContainer>
  )
}

export default ProjectEstimationForm

// Styled Component

const StyledContainer = styled(Container)`
  padding-right: 0;
  padding-left: 0;
`

const StyledForm = styled(Form)`
  padding-left: 20%;
  padding-right: 20%;
  color: black;

  @media screen and (max-width: 768px) {
    padding-left: 10%;
    padding-right: 10%;
  }
`

const PersonalInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const FormTitle = styled.div`
  padding: 5vh 0 5vh 0;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const Wrapper = styled.div`
  display: flex;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const WhiteSpace = styled.div`
  margin: 10px;

  @media screen and (max-width: 768px) {
    margin: 0;
  }
`

const FormRow = styled.div`
  width: 100%;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-weight: normal;
  font-size: min(14px)
  line-height: 16px;
`

const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #30363c;
  padding: 1vw;
  border-radius: 0.25rem;
  margin-bottom: 20px;
  cursor: pointer;
  transition: 0.3s;
  width: 100%;
  font-size: min(14px);

  &:hover {
    background: #ffb700;
  }

  @media screen and (max-width: 768px) {
    height: 75px;
  }
`

const Radio = styled.label`
  width: 25px;
  height: 25px;
  border: 2px solid white;
  border-radius: 50%;
  background-color: #30363c;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
`

const Checkmark = styled.label`
  cursor: pointer;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  border-radius: 50%;
  background-color: #ffb700;
  margin: 0;
  display: inline-block;
  opacity: 0;
  transition: opacity 0.5s ease;
`

const Input = styled.input`
  position: absolute;
  display: none;

  &:checked + ${Label} {
    background: #ffb700;
  }

  &:checked + ${Checkmark} {
    opacity: 1;
  }
`

const Desc = styled(Form.Control)`
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  resize: none;
`

const Submit = styled.div`
  padding-top: 10vh;
  padding-bottom: 10vh;
  display: flex;
  justify-content: center;
`

const StyledButton = styled(Button)`
  padding: 1em 4em 1em 4em;
  background: #ff4700;
  border: 0;
  transition: 300ms;
  border-radius: 0 0 20px 0;

  &:hover {
    background: #ff4700;
    transform: translateY(-2px);
  }

  &.btn-primary:focus,
  &.btn-primary:active {
    background-color: #ffb700 !important;
    box-shadow: 0 0 0 0.2rem #ff4700;
  }
`

const StyledSlider = styled(Form.Group)`
  width: 90%;
  margin: 10vh auto;

  & .rc-slider-track {
    background-color: #ffb700;
    height: 10px;
  }

  & .rc-slider-tooltip {
    white-space: nowrap;
  }

  & .rc-slider-mark {
    white-space: nowrap;
    top: 30px;
  }

  & .rc-slider-tooltip-arrow {
    visibility: hidden;
  }

  & .rc-slider-mark-text {
    cursor: default;

    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }

  & .rc-slider-mark-text-active {
    color: #FFB700;
  }
`
