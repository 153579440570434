import React from "react"
import { SEO as Seo } from '../components/SEO.js'
import Layout from "../components/Layout"
import ProjectEstimationConsultation from "../components/ProjectEstimationConsultation"
import ProjectEstimationForm from "../components/ProjectEstimationForm"
import ProjectEstimationHeader from "../components/ProjectEstimationHeader"

export default function ProjectEstimation() {
  return (
    <Layout>
      <Seo 
        title="Blackfort - Free Project Estimation"
        url="https://www.blackfort.ph/project-estimation"
      />
      <ProjectEstimationHeader />
      <ProjectEstimationForm />
      <ProjectEstimationConsultation />
    </Layout>
  )
}
