import React from "react"
import { ListGroup } from "react-bootstrap"
import styled from "styled-components"
import { RobotoNormal2 } from "../styles/PageStyles"

const ProjectEstimationConsultation = () => {
  return (
    <Wrapper>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <div>
          <Consultation>FREE</Consultation>
          <ListGroup>
            <FreeItem>
              <RobotoNormal2>
                1 hour (maximum) of consultation meeting.
              </RobotoNormal2>
            </FreeItem>
            <FreeItem>
              <RobotoNormal2>
                Identifying needs and technical analysis.
              </RobotoNormal2>
            </FreeItem>
            <FreeItem>
              <RobotoNormal2>Rough estimate of project cost.</RobotoNormal2>
            </FreeItem>
            <FreeItem>
              <RobotoNormal2>
                Email support (replies may be outside business hours).
              </RobotoNormal2>
            </FreeItem>
          </ListGroup>
        </div>
      </div>
      {/* <WhiteSpace />
      <div style={{ width: "100%" }}>
        <Consultation>BUSINESS</Consultation>
        <ListGroup>
          <BusinessItem>
            10 hours total (maximum) of consultation meeting.
          </BusinessItem>
          <BusinessItem>
            Detailed spec definition and technical analysis.
          </BusinessItem>
          <BusinessItem>Detailed project estimate.</BusinessItem>
          <BusinessItem>Immediate email support.</BusinessItem>
          <BusinessItem>Account manager services.</BusinessItem>
          <BusinessItem>
            Cost of the package may be included in the final project estimate /
            contract.
          </BusinessItem>
        </ListGroup>
      </div> */}
    </Wrapper>
  )
}

export default ProjectEstimationConsultation

// Styled Component

const Wrapper = styled.div`
  display: flex;
  padding-left: 20%;
  padding-right: 20%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding-left: 10%;
    padding-right: 10%;
  }
`

// const WhiteSpace = styled.div`
//   margin: 10px;

//   @media screen and (max-width: 768px) {
//     margin: 0;
//   }
// `

const Consultation = styled.p`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  margin-top: 5vh;

  background: #ffb700;
  white-space: nowrap;
  padding: 16px 40px;
  color: black;
  font-weight: 900;
  font-size: 20px;
  outline: none;
  border: none;
  min-width: 100px;
  text-decoration: none;
  border-bottom-right-radius: 20px;
`

const FreeItem = styled(ListGroup.Item)`
  background: transparent;
  display: flex;
  justify-content: center;
  text-align: center;

  @media screen and (max-width: 768px) {
    justify-content: center;
    text-align: center;
  }
`
// const BusinessItem = styled(ListGroup.Item)`
//   background: transparent;
//   display: flex;
//   justify-content: flex-start;
//   font-size: 1rem;
//   font-weight: bold;
//   text-align: left;

//   @media screen and (max-width: 768px) {
//     justify-content: center;
//     text-align: center;
//   }
// `
